<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Add Merchant Account'">
          <template v-slot:preview>
            <form enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Select Restaurant</label>
                    <div>
                      <select
                        v-model="selected_restaurant"
                        required
                        class="form-control-role input-style decorated"
                      >
                        <option disabled value="">Select Restaurant</option>
                        <option
                          v-for="restaurant in restaurants"
                          :value="restaurant.uuid"
                          v-bind:key="restaurant.uuid"
                          :title="restaurant.title"
                        >
                          {{ restaurant.title }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="text-center p-0">
                    <button
                      type="button"
                      @click="addAccount"
                      class="btn custom-btn-style text-center"
                    >
                      Add Account
                      <b-spinner
                        v-show="spinner === true"
                        variant="primary"
                        label="Text Centered"
                      ></b-spinner>
                    </button>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
            </form>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
// import ApiService from "@/core/services/api.service";
import axios from "axios";
import helpers from "../../helpers";

export default {
  data() {
    return {
      restaurants: [],
      restaurant_uuid: "",
      spinner: false,
      is_disabled: true,
      ShowDropdownList: false,
      selected_restaurant: ""
    };
  },
  computed: {},
  components: {
    KTCodePreview
  },
  methods: {
    getAllRestaurants() {
      axios
        .get(this.$path + "/admin/restaurant")
        .then(response => {
          this.restaurants = response.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    addAccount() {
      this.spinner = true;
      this.is_disabled = true;
      axios
        .get(
          this.$path +
            "/consumer/stripe/restaurant/account/connect/" +
            this.selected_restaurant
        )
        .then(({ data }) => {
          if (data.data.url) {
            window.open(data.data.url, "_blank");
            this.$router.push({ name: "all_restaurants" });
          } else {
            helpers.errorMessage(this, "Account Already Added!");
            this.spinner = false;
            this.is_disabled = false;
          }
        })
        .catch(err => {
          this.spinner = false;
          this.is_disabled = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.getAllRestaurants();
  },
  watch: {}
};
</script>
<style scoped>
.position-relative input.form-control {
  width: 100% !important;
}
option:hover {
  background-color: yellow !important;
}
</style>
